<template>
  <div class="subject-main">
    <div class="subject-title">
      <span class="subject-tag">单选题</span>
      <span>{{ questionIndex + 1 }}、</span>
      <span>{{ question['question'] }}</span>
    </div>
    <div class="subject-option">
      <div v-for="(value, key, index) in JSON.parse(question['questionContent'])" :key="index">
        <div class="option-item" @click="choose(key, value)">
          <section v-if="trainning">
            <div
              class="option-item-tag-answer"
              v-if="choosed && (chooseKey === key || key === question['questionAnswer'])"
            >
              <img
                class="answer-img"
                v-if="key == question['questionAnswer']"
                src="@/assets/images/true.png"
                alt=""
                srcset=""
              />
              <img class="answer-img" v-else src="@/assets/images/false.png" alt="" srcset="" />
            </div>
            <div class="option-item-tag" v-else>
              <div>{{ key }}</div>
            </div>
          </section>
          <section v-else>
            <div class="option-item-tag" :class="{ 'option-item-tag-choosed': chooseKey === key }">
              <div>{{ key }}</div>
            </div>
          </section>
          <div class="option-item-content">
            {{ value }}
          </div>
        </div>
      </div>
    </div>
    <div class="analysis-main" v-if="choosed">
      <div class="correct-answer">
        <span class="correct-answer-title">答案：</span>
        <span class="correct-answer-key">{{ question['questionAnswer'] }}</span>
        <span>您的选择：</span>
        <span class="choosed-key">{{ chooseKey }}</span>
      </div>
      <div class="analysis-content">
        <div class="analysis-content-title">题目解析</div>
        <div>{{ question['questionAnalysis'] }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultipleChoiceQuestion',
  components: {},
  props: {
    questionIndex: {
      type: Number,
      default: 0,
    },
    trainning: {
      Boolean,
      default: false,
    },
    question: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      choosed: false,
      chooseKey: null,
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    choose(key, value) {
      if (this.choosed) return
      this.chooseKey = key
      if (this.trainning) {
        this.choosed = true
      }
      this.$emit('choose', key, this.questionIndex, this.question)
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.subject-main {
  font-size: 15px;
  color: black;
  height: 100%;
  padding: 10px 20px;
  background-color: #f5f5f5;
  line-height: 25px;
  .subject-title {
    .subject-tag {
      color: white;
      padding: 3px 7px;
      background-color: #409eff;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
  .subject-option {
    // line-height: 40px;
    .option-item {
      // height: 40px;
      margin: 5px 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      // align-items: center;
      align-items: flex-start;
      .option-item-tag {
        flex-shrink: 0;
        background-color: #e4e7ed;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .option-item-tag-choosed {
        background-color: #f56c6c;
      }
      .option-item-tag-answer {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        .answer-img {
          width: 32px;
          height: 32px;
        }
      }
      .option-item-content {
        // line-height: 18px;
      }
    }
  }
  .analysis-main {
    .correct-answer {
      padding: 5px 10px;
      background-color: rgba(144, 144, 173, 0.3);
      .correct-answer-title {
        font-weight: bold;
      }
      .correct-answer-key {
        color: #409eff;
        margin-right: 10px;
      }
      .choosed-key {
        color: #f56c6c;
      }
    }
    .analysis-content {
      margin-top: 10px;
      border-top: 1px solid #646d67;
      .analysis-content-title {
        font-weight: bold;
      }
    }
  }
}
</style>
