var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subject-main" }, [
    _c("div", { staticClass: "subject-title" }, [
      _c("span", { staticClass: "subject-tag" }, [_vm._v("单选题")]),
      _c("span", [_vm._v(_vm._s(_vm.questionIndex + 1) + "、")]),
      _c("span", [_vm._v(_vm._s(_vm.question["question"]))]),
    ]),
    _c(
      "div",
      { staticClass: "subject-option" },
      _vm._l(
        JSON.parse(_vm.question["questionContent"]),
        function (value, key, index) {
          return _c("div", { key: index }, [
            _c(
              "div",
              {
                staticClass: "option-item",
                on: {
                  click: function ($event) {
                    return _vm.choose(key, value)
                  },
                },
              },
              [
                _vm.trainning
                  ? _c("section", [
                      _vm.choosed &&
                      (_vm.chooseKey === key ||
                        key === _vm.question["questionAnswer"])
                        ? _c("div", { staticClass: "option-item-tag-answer" }, [
                            key == _vm.question["questionAnswer"]
                              ? _c("img", {
                                  staticClass: "answer-img",
                                  attrs: {
                                    src: require("@/assets/images/true.png"),
                                    alt: "",
                                    srcset: "",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "answer-img",
                                  attrs: {
                                    src: require("@/assets/images/false.png"),
                                    alt: "",
                                    srcset: "",
                                  },
                                }),
                          ])
                        : _c("div", { staticClass: "option-item-tag" }, [
                            _c("div", [_vm._v(_vm._s(key))]),
                          ]),
                    ])
                  : _c("section", [
                      _c(
                        "div",
                        {
                          staticClass: "option-item-tag",
                          class: {
                            "option-item-tag-choosed": _vm.chooseKey === key,
                          },
                        },
                        [_c("div", [_vm._v(_vm._s(key))])]
                      ),
                    ]),
                _c("div", { staticClass: "option-item-content" }, [
                  _vm._v(" " + _vm._s(value) + " "),
                ]),
              ]
            ),
          ])
        }
      ),
      0
    ),
    _vm.choosed
      ? _c("div", { staticClass: "analysis-main" }, [
          _c("div", { staticClass: "correct-answer" }, [
            _c("span", { staticClass: "correct-answer-title" }, [
              _vm._v("答案："),
            ]),
            _c("span", { staticClass: "correct-answer-key" }, [
              _vm._v(_vm._s(_vm.question["questionAnswer"])),
            ]),
            _c("span", [_vm._v("您的选择：")]),
            _c("span", { staticClass: "choosed-key" }, [
              _vm._v(_vm._s(_vm.chooseKey)),
            ]),
          ]),
          _c("div", { staticClass: "analysis-content" }, [
            _c("div", { staticClass: "analysis-content-title" }, [
              _vm._v("题目解析"),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.question["questionAnalysis"]))]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }